export const RUNSHEET_HEADERS = [
    { id: 'grantors', label: { deed: 'Grantor', lease: 'Lessor' }, defaultChecked: true },
    { id: 'grantees', label: { deed: 'Grantee', lease: 'Lessee' }, defaultChecked: true },
    { id: 'fullGrantor', label: { deed: 'Full Grantor', lease: 'Full Lessor' }, defaultChecked: false },
    { id: 'fullGrantee', label: { deed: 'Full Grantee', lease: 'Full Lessor' }, defaultChecked: false },
    { id: 'bookType', label: 'Book Type', defaultChecked: true },
    { id: 'bookVolume', label: 'Book Volume', defaultChecked: true },
    { id: 'bookPage', label: 'Page', defaultChecked: true },
    { id: 'instrumentNumber', label: 'Instrument #', defaultChecked: true },
    { id: 'instrumentType', label: 'Instrument Type', defaultChecked: true },
    { id: 'instrumentDate', label: 'Instrument Date', defaultChecked: true },
    { id: 'fileDate', label: 'File Date', defaultChecked: true },
    { id: 'legalDescriptions', label: 'Legal Description', defaultChecked: true },
    { id: 'comment', label: 'Comment', defaultChecked: true },
    { id: 'recordingDate', label: 'Recording Date', defaultChecked: false },
    
]

export const RUNSHEETS_HEADERS = {
    'Runsheet Name': { key: 'name', sortable: true, dataSort: 'name' },
    'Number of Documents': { key: 'numberOfDocuments', sortable: false },
    'Assigned To': { key: 'assignedTo', sortable: true, dataSort: 'assigned' },
    'Status': { key: 'status', sortable: true, dataSort: 'status' },
    'Status Date': { key: 'statusDate', sortable: true, dataSort: 'statusDate' },
    'Cert. Date': { key: 'certDate', sortable: true, dataSort: 'cert_date' },
}

export const PARTY_CASING = {
    'none': 'Original',
    'upper': 'Upper',
}

export const CONVEYANCE_TYPES = [
    'surface',
    'executive',
    'bonus',
    'delay',
    'royalty',
]

export const TITLE_BREAKDOWN_STATUSES = {
    PROCESSING: 'processing',
    COMPLETED: 'completed',
    MANUAL: 'manual',
    MANUAL_PROCESSING: 'manual_processing',
    STALE: 'stale',
    FAILED: 'failed',
    MANUAL_FAILED: 'manual_failed',
}

export const TITLE_BREAKDOWN_PROCESSING = [
    TITLE_BREAKDOWN_STATUSES.PROCESSING,
    TITLE_BREAKDOWN_STATUSES.MANUAL_PROCESSING,
]

export const NUMBER_DISPLAY_TYPE = {
    PERCENT: 'percent',
    DECIMAL: 'decimal',
}


/**
 * Regex to validate whether its an arithmetical formula. it accepts thee e4 basic calculations and parenthesis.
 * It can start with an equals sign (=) to mimic excel's behavior
 * 
 * @example =(1/2+1/4)+1/6*1/8 // this works
 * @example 1/2+1/4 // this works
 * @example 1/2+0.5 // this works
 * @example 1/2+hackerman // this wont work
 */
export const CONVEYANCE_REGEX = /^=?[\d/*+\-().]+[\d)]?$/

export const TITLE_BREAKDOWN_ROW_SIZE = 29

export const MAIN_TAB = '0'